<template>
    <div @click="onClick">
        <van-field is-link readonly :label="title" :placeholder="placeholder" :value="selectedName" :rules="[{ required: required }]" @click="onShowPopup" />
        <van-popup v-model="popupVisible" :lazy-render="false" round position="bottom" class="popup-box">
            <van-cell-group :border="false" :title="placeholder" class="popup-cell">
                <van-search v-if="filterable" v-model="filterValue" placeholder="请输入搜索关键词" @search="onFilter" />
                <check-item-group ref="group" v-model="selectedValue" @change="onChange">
                    <check-item v-if="clear" :label="clearText" icon @click="onClear"></check-item>
                    <slot></slot>
                </check-item-group>
            </van-cell-group>
        </van-popup>
    </div>
</template>

<script>
import CheckItemGroup from "@/components/MT/CheckItemGroup";
import CheckItem from "@/components/MT/CheckItem";
export default {
    name: "CheckCellGroup",
    components: {
        CheckItemGroup,
        CheckItem,
    },
    model: {
        prop: 'value',
        event: 'update:value'
    },
    computed: {
        selectedValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        selectedName() {
            if (this.$refs.group && typeof this.value.length > 0) {
                var arr = [];
                for (let i = 0; i < this.$refs.group.$slots.default.length; i++) {
                    let item = this.$refs.group.$slots.default[i].child;
                    if (item && this.value.includes(item.name)) {
                        arr.push(item.label);
                    }
                }
                return arr.join(',');
            }
            return this.value.join(',');
        },
    },
    props: {
        value: {
            type: Array,
            default: [],
        },
        title: {
            type: String,
            default: '标题',
        },
        placeholder: {
            type: String,
            default: '请选择',
        },
        clearText: {
            type: String,
            default: '全部',
        },
        filterable: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        clear: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            popupVisible: false,
            filterValue: null
        };
    },
    methods: {
        onShowPopup() {
            let e = {
                prevent: false
            };
            this.$emit("show-popup", e);
            if (!e.prevent) {
                this.popupVisible = true;
            }
        },
        onHidePopup() {
            let e = {
                prevent: false
            };
            this.$emit("hide-popup", e);
            if (!e.prevent) {
                this.popupVisible = false;
            }
        },
        onClick() {
            this.$emit("click");
        },
        onClear() {
            this.selectedValue = [];
        },
        onChange() {
            this.$emit("change");
        },
        onFilter() {
            for (let i = 0; i < this.$refs.group.$slots.default.length; i++) {
                let item = this.$refs.group.$slots.default[i].child;
                if (item && item.setVisible) {
                    //设置显隐
                    item.setVisible(item.label.includes(this.filterValue));
                }
            }
        },
    },
    mounted() {

    }
};
</script>

<style scoped lang="less">
.popup-box {
    height: 80%;
}

.popup-cell {
    margin: 0px 10px 10px 10px;
}
</style>