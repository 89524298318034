<template>
    <div @click="onClick">
        <van-field is-link readonly :label="title" :placeholder="placeholder" :value="selectedName" :rules="[{ required: required }]" @click="onShowPopup" />
        <van-popup v-model="popupVisible" :lazy-render="false" round position="bottom" class="popup-box">
            <van-cell-group :border="false" :title="placeholder" class="popup-cell">
                <van-search v-if="filterable" v-model="filterValue" placeholder="请输入搜索关键词" @search="onFilter" />
                <radio-item-group ref="group" v-model="selectedValue" @change="onChange" @click="onHidePopup">
                    <radio-item v-if="clear" :label="clearText" name="" icon @click="onClear"></radio-item>
                    <slot></slot>
                </radio-item-group>
            </van-cell-group>
        </van-popup>
    </div>
</template>

<script>
import RadioItemGroup from "@/components/MT/RadioItemGroup";
import RadioItem from "@/components/MT/RadioItem";
export default {
    name: "RadioCellGroup",
    components: {
        RadioItemGroup,
        RadioItem,
    },
    model: {
        prop: 'value',
        event: 'update:value'
    },
    computed: {
        selectedValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        selectedName() {
            if (this.$refs.group && typeof this.value != "undefined" && this.value != null && this.value != "") {
                for (let i = 0; i < this.$refs.group.$slots.default.length; i++) {
                    let item = this.$refs.group.$slots.default[i].child;
                    if (item && item.name == this.value) {
                        return item.label;
                    }
                }
            }
            return this.value;
        },
    },
    props: {
        value: {
            type: [String, Object],
            default: null,
        },
        title: {
            type: String,
            default: '标题',
        },
        placeholder: {
            type: String,
            default: '请选择',
        },
        clearText: {
            type: String,
            default: '全部',
        },
        filterable: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        clear: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            popupVisible: false,
            filterValue: null,
        };
    },
    methods: {
        onShowPopup() {
            let e = {
                prevent: false
            };
            this.$emit("show-popup", e);
            if (!e.prevent) {
                this.popupVisible = true;
            }
        },
        onHidePopup() {
            let e = {
                prevent: false
            };
            this.$emit("hide-popup", e);
            if (!e.prevent) {
                this.popupVisible = false;
            }
        },
        onClick() {
            this.$emit("click");
        },
        onClear() {
            this.selectedValue = "";
        },
        onChange() {
            this.$emit("change");
        },
        onFilter() {
            for (let i = 0; i < this.$refs.group.$slots.default.length; i++) {
                let item = this.$refs.group.$slots.default[i].child;
                //设置显隐
                if (item && item.setVisible) {
                    item.setVisible(item.label.includes(this.filterValue));
                }
                //全匹配默认选中
                if (item && item.label == this.filterValue) {
                    this.selectedValue = item.name;
                    this.$emit("change", this.selectedValue);
                }
            }
        },
    },
    mounted() {

    }
};
</script>

<style scoped lang="less">
.popup-box {
    height: 80%;
}

.popup-cell {
    margin: 0px 10px 10px 10px;
}
</style>