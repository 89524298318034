<template>
  <div class="login-box">
    <van-cell-group>
      <van-form ref="form">
        <van-field v-model="formData.studentName" name="姓名" label="姓名" placeholder="姓名" :rules="[{ required: true, message: '请填写姓名' }]" />
        <van-field v-model="formData.studentPhone" name="电话" label="电话" placeholder="电话" :rules="[{ required: true, message: '请填写电话' }]" />
        <van-field v-model="formData.studentSchool" name="毕业院校" label="毕业院校" placeholder="毕业院校" :rules="[{ required: true, message: '请填写毕业院校' }]" />
        <van-field v-model="formData.studentMajor" name="所学专业" label="所学专业" placeholder="所学专业" :rules="[{ required: true, message: '请填写所学专业' }]" />
        <van-field v-model="formData.studentGraded" name="四六级" label="四六级" placeholder="选择四六级" readonly clickable :rules="[{ required: true, message: '请选择四六级' }]" @click="showSelect = true" />
        <van-field v-model="formData.studentScore" name="四六级分数" label="四六级分数" placeholder="四六级分数" :rules="[{ required: true, message: '请填写四六级分数' }]" />
        <div style="margin: 15px">
          <van-row gutter="20">
            <van-col span="12">
              <van-button round block type="default" @click="onCancel">取消</van-button>
            </van-col>
            <van-col span="12">
              <van-button round block type="danger" @click="onSubmit">生成报告</van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </van-cell-group>
    <van-popup v-model="showSelect" position="bottom" get-container="body">
      <van-picker show-toolbar :columns="['未过四级', '四级', '六级']" @confirm="onConfirmSelect" @cancel="onCancelSelect" />
    </van-popup>
  </div>
</template>

<script>
import Config from "@/api/service";

export default {
  name: "StudentDialog1",
  components: {
  },
  props: {
    studentName: {
      type: String,
      default: '',
    },
    studentPhone: {
      type: String,
      default: '',
    },
    studentSchool: {
      type: String,
      default: '',
    },
    studentMajor: {
      type: String,
      default: '',
    },
    studentGraded: {
      type: String,
      default: '',
    },
    studentScore: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showSelect: false,
      formData: {
        studentName: null,
        studentPhone: null,
        studentSchool: null,
        studentMajor: null,
        studentGraded: null,
        studentScore: null,
      },
    };
  },
  methods: {
    //提交
    onSubmit() {
      this.$refs.form.validate().then(() => {
        this.$emit('submit', this.formData);
      }).catch(() => {

      })
    },
    //取消
    onCancel() {
      this.$emit('cancel');
    },
    //提交选择
    onConfirmSelect(val) {
      this.formData.studentGraded = val;
      this.showSelect = false;
    },
    //取消选择
    onCancelSelect() {
      this.showSelect = false;
    },
  },
};
</script>

<style scoped>
.login-box {
  width: 100%;
  padding: 10px 0;
}
</style>
