<template>
  <div>
    <div v-show="isDocin" class="m-header-top m-header-top-end m-header-top-small">
      <header>
        <span class="header-toolbar-title">
          <img src="https://hs.douding.cn/images_cn/iphone/collect/docin_logo_big@3x.png" width="13.98" height="15.41" alt="">
          <span>豆丁考研</span>
        </span>
        <div class="header-toolbar-right">
          <div class="toolbar_more"><span class="ico_more_btn"></span></div>
        </div>
      </header>
      <div class="header_nav_list">
        <ul class="header_nav_list_ul">
          <li><a href="https://www.docin.com/touch_new/index.do" title="首页">首页</a></li>
          <li><a href="https://www.docin.com/touch_new/category1.do" title="分类">分类</a></li>
          <li><a href="https://www.docin.com/touch_new/topic_index.do" title="专题">专题</a></li>
          <li><a href="https://www.docin.com/touch_new/major_index.do" title="大会员">大会员</a></li>
          <li><a href="https://www.docin.com/touch_new/mymenu.do" title="我的" class="info-my">我的</a></li>
          <li><a href="https://jz.docin.com/touch_new/build/index.do" title="建筑">建筑</a></li>
          <li><a href="https://www.docin.com/touch_new/touchkyzqindex.do" title="考研">考研</a></li>
          <li><a href="https://www.docin.com/touch_new/bcaseIndex.do" title="创业">创业</a></li>
          <li><a href="https://www.docin.com/touch_new/homeStudy/index.do" title="在家学">在家学</a></li>
          <li><a href="http://ke.docin.com/touch/index" title="课堂">课堂</a></li>
          <li><a href="https://www.docin.com/jsp_cn/touchPay/touch_fin_new.jsp" title="充值">充值</a></li>
          <li><a href="https://www.docin.com/touch_new/touchMedicalIndex.do" title="医疗">医疗</a></li>
          <li><a href="https://mobile.docin.com/bookstore_touch/index.html" title="书城">书城</a></li>
          <li><a href="https://www.docin.com/touch_new/minicaseIndex.do" title="微案例">微案例</a></li>
          <li><a href="https://www.docin.com/touch_new/daily_index.do" title="日报">日报</a></li>
          <li><a href="https://www.docin.com/touch_new/video_red_out.do" title="素材">素材</a></li>
          <li><a href="https://www.docin.com/touch_new/mysub.do" title="订阅">订阅</a></li>
          <li><a href="http://t.cn/S4rUG6" title="下载App">下载App</a></li>
          <li><a href="https://www.docin.com/touch_new/zuowenIndex.do" title="作文">作文</a></li>
          <li><a href="https://www.docin.com/jsp_cn/productEnd/pay/partner/touch/index.jsp" title="合伙人">合伙人</a></li>
        </ul>
      </div>
    </div>

    <div class="titlebar-box">
      <van-icon name="arrow-left" class="titlebar-back" @click="onBack" />
      <div class="titlebar-title-box">
        <div class="titlebar-title-txt">{{ title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from "@/api/service";
import jQuery from 'jquery';

export default {
  name: "TitleBar2",
  props: {
    title: {
      type: String,
      default: '标题',
    },
  },
  data() {
    return {
      isDocin: false,
    };
  },
  methods: {
    getIsDocin() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.proxy.isDocin, model)
        .then((res) => {
          this.isDocin = res.data.Data.IsDocin;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    touchBatInit() {
      function touchFunInit() {
        openChannel();
        backStep();
      }
      function backStep() {
        var oBackBtn = jQuery(".m-header-top-end .header-back .ico_btn_back");
        if (oBackBtn.length > 0) {
          oBackBtn.bind("click", function () {
            touch_back();
          });
        }
      }
      function openChannel() {
        var oShowNavlistBtn = jQuery(".toolbar_more"),
          oToolHeadTop = jQuery(".m-header-top");
        if (jQuery(".header_nav_list").length > 0) {
          jQuery(".header_nav_list").css("height", 0);
        }
        if (oShowNavlistBtn.length > 0 && oToolHeadTop.length > 0) {
          jQuery(".m-header-top .header_nav_list").addClass("headerBg");
          var oNavListHeight = "130px";
          jQuery(".header_nav_list").css("height", 0);
          var headerBarHeight = jQuery(".m-header-top").height();
          jQuery("body").css({
            // 'paddingTop': headerBarHeight + 'px'
          });
          oShowNavlistBtn.bind("click", function () {
            if (jQuery(this).find('span').hasClass("ico_more_up")) {
              jQuery(this).find('span').removeClass("ico_more_up");
              jQuery("body").css({
                // 'paddingTop': headerBarHeight,
                'webkitTransition': 'all .3s',
              });
              jQuery(".header_nav_list").css({
                'opacity': '0',
                'min-height': '0',
                'height': '0'
              });
            } else {
              jQuery(this).find('span').addClass("ico_more_up");
              jQuery(".header_nav_list").css({
                'opacity': '1',
                'min-height': oNavListHeight,
                'height': 'auto'
              });
              jQuery("body").css({
                // 'paddingTop': jQuery('.header_nav_list').height() + jQuery('.m-header-top header').outerHeight(),
                'webkitTransition': 'all .3s'
              });
            }
          });
        } else if (oShowNavlistBtn.length == 0 && jQuery(".m-header-top-end").length == 1) {
          jQuery("body").css({
            // 'paddingTop': jQuery(".m-header-top header").outerHeight() + 'px'
          });
        }
      }
      function touch_back() {
        var referUrl = (document.referrer ? document.referrer : "");
        if (referUrl != "") {
          var curDomaim = getHost(referUrl);
          var reg = /.docin.com/g;
          var reg2 = /nologin_pay_middle/g;
          var reg3 = /wx_outer_pay_middle/g;
          if (!reg.test(curDomaim) || reg2.test(referUrl) || reg3.test(referUrl)) {
            turnTo("https://www.docin.com/touch_new/index.do");
          } else {
            window.history.back();
          }
        } else {
          turnTo("https://www.docin.com/touch_new/index.do");
        }
      }
      function turnTo(url, pageNum) {
        if (!url) {
          return;
        }
        if (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || /MSIE(\d+\.\d+);/.test(navigator.userAgent)) {
          var referLink = document.createElement('a');
          if (pageNum != null && pageNum > 1) {
            if (url.indexOf("?") > 0) {
              url = url + "&prePageNum=" + pageNum;
            } else {
              url = url + "?prePageNum=" + pageNum;
            }
          }
          referLink.href = url;
          document.body.appendChild(referLink);
          referLink.click();
        } else {
          if (pageNum != null && pageNum > 1) {
            if (url.indexOf("?") > 0) {
              url = url + "&backPageNum=" + pageNum;
            } else {
              url = url + "?backPageNum=" + pageNum;
            }
          }
          location.href = url;
        }
      }
      function getHost(fullurl) {
        var n = fullurl.indexOf('://');
        if (n != -1) {
          return fullurl.split('://')[1].split('/')[0].split('?')[0];
        } else {
          return fullurl.split('/')[0].split('?')[0];
        }
      }
      touchFunInit();
    },
    onBack() {
      this.$router.back();
    },
  },
  mounted() {
    this.getIsDocin();
    this.touchBatInit();
  }
};
</script>

<style lang="less" scoped>
.titlebar-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-width: 0px;
  background-color: #F6F6F6;
  height: 50px;
}

.titlebar-back {
  position: absolute;
  margin-left: 10px;
}

.titlebar-title-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.titlebar-title-txt {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 30px;
}

::v-deep .van-search__content {
  border: 1px solid #FE5E03;
  background-color: white;
}

/**豆丁顶部菜单**/
a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

.m-header-top {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
}

// .m-header-top-end {
//   position: fixed;
// }

.m-header-top header {
  height: 43px;
  line-height: 43px;
  border-bottom: 1px solid #efefef;
  text-align: center;
  background-color: #fff;
}

.m-header-top-small header {
  height: 22px;
  line-height: 22px;
  background-color: #F6F6F6;
  border: 0;
}

.m-header-top .header-toolbar-left {
  float: left;
}

.header-toolbar-left .header-back {
  width: 54px;
  height: 42px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}

.ico_btn_back {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: url(https://hs.douding.cn/images_cn/iphone/collect/ico_btn_back.png) no-repeat;
  background-size: 11px 19px;
  background-position: 50%;
}

.m-header-top .header-toolbar-title {
  display: inline-block;
  line-height: 43px;
  vertical-align: top;
  font-size: 0;
  white-space: nowrap;
  margin: 0 80px;
}

.m-header-top-small .header-toolbar-title {
  line-height: 22px;
}

.m-header-top .header-toolbar-title img,
.m-header-top .header-toolbar-title span {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  color: #040000;
}

.m-header-top .header-toolbar-title span {
  position: relative;
  top: 1px;
  font-size: 13px;
  font-weight: 500;
}

.m-header-top .header-toolbar-title img {
  margin-right: 5.9px;
}

.m-header-top-small .header-toolbar-title img {
  margin-right: 4.1px;
}

.m-header-top .header-toolbar-right {
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 40px;
  background: -webkit-linear-gradient(left, #fff 0, #fff 30%);
  background: -webkit-linear-gradient(left, hsla(0, 0%, 100%, 0) 0, #fff 30%);
  font-size: 0;
}

.m-header-top-small .header-toolbar-right {
  background: none;
}

.m-header-top .toolbar_more {
  display: inline-block;
  width: 48px;
  height: 42px;
  vertical-align: top;
}

.m-header-top-small .toolbar_more {
  height: 22px;
}

.ico_more_btn {
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 5px;
  background: url(https://hs.douding.cn/images_cn/iphone/collect/ico_three_point_3x.png) 0 0 no-repeat;
  background-size: 23px 5px;
}

.ico_more_up {
  width: 19px;
  height: 11px;
  background: url(https://hs.douding.cn/images_cn/iphone/collect/ico_type_up_3x.png) 0 0 no-repeat;
  background-size: 19px 11px;
}

.m-header-top-small .ico_more_up {
  width: 23px;
  height: 5px;
  background: url(https://hs.douding.cn/images_cn/iphone/collect/ico_three_point_blue_3x.png) 0 0 no-repeat;
  background-size: 23px 5px;
}

.m-header-top-end .toolbar_more .ico_more_up {
  vertical-align: -4px;
}

.m-header-top-small .toolbar_more .ico_more_up {
  vertical-align: middle !important;
}

.header_nav_list {
  background-color: #3479ba;
  transition: all .3s;
  opacity: 0;
  overflow: hidden;
  height: 0;
}

.headerBg {
  background: url(https://hs.douding.cn/images_cn/iphone/miniDocin/index_top_bg.jpg?rand=20190702) 0 0 no-repeat;
  background-size: cover;
}

.header_nav_list_ul {
  display: table;
  width: 100%;
  padding: 5px 0;
}

.header_nav_list_ul li {
  width: 20%;
  display: inline-block;
  text-align: center;
  height: 30px;
  line-height: 30px;
}

.header_nav_list_ul li a {
  color: #fff;
  font-size: 14px;
}
</style>
