<template>
  <div class="box" @click="onItemClick">
    <div class="box-top">
      <van-image :src="img" class="box-top_img" />
      <div class="box-top_content">
        <div class="box-top_line1">
          <span class="box-top_title">{{ school }}-{{ major }}</span>
          <van-button v-if="compareVisible" class="compare-button" icon="plus" size="mini" type="info" plain @click.stop="onCompareClick">对比</van-button>
        </div>
        <div class="box-top_line2">
          <div class="box-top_item">
            <span>专业代码：</span>
            <span>{{ code }}</span>
          </div>
          <div class="box-top_item">
            <span>位置：</span>
            <span>{{ partition }}区</span>
          </div>
          <div class="box-top_item">
            <img src="@/assets/images/mt/position.png" class="province-img" />
            <span>{{ province }}</span>
          </div>
        </div>
      </div>
    </div>
    <van-row class="box-bottom" type="flex">
      <van-col class="box-bottom_item" span="8">
        <div class="box-bottom_title">{{ college }}</div>
        <div class="box-bottom_text">所属院系</div>
      </van-col>
      <van-col class="box-bottom_item" span="8">
        <div class="box-bottom_title">{{ learnway }}</div>
        <div class="box-bottom_text">学习方式</div>
      </van-col>
      <van-col class="box-bottom_item" span="8">
        <div class="box-bottom_title" v-if="admitInfoList.length > 0">{{ admitYear || admitInfoList[0].AdmitYear }}</div>
        <div class="box-bottom_text">录取年份</div>
      </van-col>
    </van-row>
    <van-swipe class="swipe-box" autoplay="-" indicator-color="#FE5E03" @change="onSwipeChange">
      <van-swipe-item v-for="(item, index) in admitInfoList" :key="index" class="swipe-item">
        <van-row class="box-bottom" type="flex" justify="center">
          <van-col class="box-bottom_item" span="8">
            <div class="box-bottom_title" v-if="unlocked">{{ item.AdmitTotalNumber || '--' }}</div>
            <van-image v-else class="box-bottom_img" fit="contain" :src="require('@/assets/images/comm/lock.png')" @click.stop="onUnlockClick"></van-image>
            <div class="box-bottom_text">总录取人数</div>
          </van-col>
          <van-col class="box-bottom_item" span="8">
            <div class="box-bottom_title" v-if="unlocked">{{ item.AdmitVolunteerNumber || '--' }}</div>
            <van-image v-else class="box-bottom_img" fit="contain" :src="require('@/assets/images/comm/lock.png')" @click.stop="onUnlockClick"></van-image>
            <div class="box-bottom_text">一志愿录取</div>
          </van-col>
          <van-col class="box-bottom_item" span="8">
            <div class="box-bottom_title" v-if="unlocked">{{ item.AdmitAdjustedNumber || '--' }}</div>
            <van-image v-else class="box-bottom_img" fit="contain" :src="require('@/assets/images/comm/lock.png')" @click.stop="onUnlockClick"></van-image>
            <div class="box-bottom_text">调剂人数</div>
          </van-col>
        </van-row>
        <van-row class="box-bottom" type="flex" justify="center">
          <van-col class="box-bottom_item" span="8">
            <div class="box-bottom_title" v-if="unlocked">{{ item.AdmitVolunteerMinScore || '--' }}</div>
            <van-image v-else class="box-bottom_img" fit="contain" :src="require('@/assets/images/comm/lock.png')" @click.stop="onUnlockClick"></van-image>
            <div class="box-bottom_text">一志愿最低分</div>
          </van-col>
          <van-col class="box-bottom_item" span="8">
            <div class="box-bottom_title" v-if="unlocked">{{ item.AdmitAdjustMinScore || '--' }}</div>
            <van-image v-else class="box-bottom_img" fit="contain" :src="require('@/assets/images/comm/lock.png')" @click.stop="onUnlockClick"></van-image>
            <div class="box-bottom_text">调剂最低分</div>
          </van-col>
          <van-col class="box-bottom_item" span="8">
            <div class="box-bottom_title" v-if="unlocked">{{ item.AdmitTargetScore || '--' }}</div>
            <van-image v-else class="box-bottom_img" fit="contain" :src="require('@/assets/images/comm/lock.png')" @click.stop="onUnlockClick"></van-image>
            <div class="box-bottom_text">目标分数</div>
          </van-col>
        </van-row>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: "AiSelectionItem",
  components: {

  },
  props: {
    unlocked: {
      type: Boolean,
      default: false,
    },
    compareVisible: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: null,
    },
    school: {
      type: String,
      default: '北京大学',
    },
    major: {
      type: String,
      default: '科学技术',
    },
    province: {
      type: String,
      default: '北京',
    },
    partition: {
      type: String,
      default: 'A',
    },
    year: {
      type: String,
      default: '2023',
    },
    code: {
      type: String,
      default: '100100',
    },
    learnway: {
      type: String,
      default: '全日制',
    },
    number: {
      type: String,
      default: '10',
    },
    college: {
      type: String,
      default: '科学技术学院',
    },
    admitInfoList: {
      type: Array,
      default: [],
    },
  },
  computed: {
    characteristicGroup: function () {
      var arr = this.characteristic.split(' ');
      arr.splice(0, 2);
      return arr;
    }
  },
  data() {
    return {
      admitYear: null,
    };
  },
  methods: {
    onSwipeChange(index) {
      this.admitYear = this.admitInfoList[index].AdmitYear;
    },
    onItemClick() {
      this.$emit("item-click");
    },
    onUnlockClick() {
      this.$emit("unlock-click");
    },
    onCompareClick() {
      this.$emit("compare-click");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.box:hover {
  color: #FF6602;
}

.box-top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .box-top_img {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .box-top_content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .box-top_line1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .box-top_title {
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
      }
    }

    .box-top_line2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;

      .box-top_item {
        font-size: 13px;
        color: #888;
        margin-right: 5px;
        white-space: nowrap;
      }
    }
  }
}

.box-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .box-bottom_item {
    text-align: center;
  }

  .box-bottom_title {
    color: rgba(0, 0, 0, .64);
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 5px 0;
  }

  .box-bottom_text {
    color: rgba(0, 0, 0, .24);
    font-size: 12px;
  }

  .box-bottom_img {
    height: 15px;
  }
}

.province-img {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.compare-button {
  white-space: nowrap;
  color: #FF6602;
  border: solid 1px #FF6602;
}

.swipe-box {
  width: 100%;
}

.swipe-item {
  padding-bottom: 20px;
}

::v-deep .van-swipe__indicator {
  background-color: white;
  opacity: 1;
}

::v-deep .van-swipe__indicators {
  background-color: rgba(190, 190, 190, 0.3);
  padding: 2px 5px;
  border-radius: 10px;
  position: absolute;
  bottom: 6px;
}
</style>
