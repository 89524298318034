<template>
  <div>
    <el-form ref="form" :model="formData" :rules="formRules" :inline="false" label-suffix="" size="small" class="form-box">
      <el-row class="title">个人信息</el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="" prop="studentName">
            <el-input v-model="formData.studentName" placeholder="姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="" prop="studentPhone">
            <el-input v-model="formData.studentPhone" placeholder="电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="" prop="studentSchool">
            <el-input v-model="formData.studentSchool" placeholder="毕业院校"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="" prop="studentMajor">
            <el-input v-model="formData.studentMajor" placeholder="所学专业"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="title">四六级</el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="" prop="studentGraded">
            <el-select v-model="formData.studentGraded" placeholder="选择四六级" style="width: 100%;">
              <el-option label="未过四级" value="未过四级"></el-option>
              <el-option label="四级" value="四级"></el-option>
              <el-option label="六级" value="六级"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="" prop="studentScore">
            <el-input v-model="formData.studentScore" type="number" placeholder="四六级分数"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item class="button">
        <el-button type="primary" @click="onSubmit">开始生成报告</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Config from "@/api/service";

export default {
  name: "StudentDialog1",
  components: {
  },
  props: {
    studentName: {
      type: String,
      default: '',
    },
    studentPhone: {
      type: String,
      default: '',
    },
    studentSchool: {
      type: String,
      default: '',
    },
    studentMajor: {
      type: String,
      default: '',
    },
    studentGraded: {
      type: String,
      default: '',
    },
    studentScore: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formData: {
        studentName: null,
        studentPhone: null,
        studentSchool: null,
        studentMajor: null,
        studentGraded: null,
        studentScore: null,
      },
      formRules: {
        studentName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        studentPhone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        studentSchool: [
          { required: true, message: '请输入毕业院校', trigger: 'blur' }
        ],
        studentMajor: [
          { required: true, message: '请输入所学专业', trigger: 'blur' }
        ],
        studentGraded: [
          { required: true, message: '请选择四六级', trigger: 'blur' }
        ],
        studentScore: [
          { required: true, message: '请输入四六级分数', trigger: 'blur' }
        ],
      },
    };
  },
  methods: {//提交
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('submit', this.formData);
        }
      });
    },
    //取消
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped lang="less">
.form-box {
  padding: 0px 10px;

  .title {
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
}
</style>
