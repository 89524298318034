<template>
    <div class="group" @click="$emit('click')">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "CheckBorderGroup",
    provide() {
        return {
            CheckBorderGroup: this   //将组件本身的vue对象传递给下级
        };
    },
    model: {
        prop: 'value',
        event: 'update:value'
    },
    props: {
        value: {
            type: Array,
            default: []
        }
    },
    //设置值的时候无需触发change事件，手动点击切换再触发change事件
    // watch: {
    //     value: {
    //         immediate: false,
    //         handler(val) {
    //             this.$emit("change", val);
    //         }
    //     },
    // },
    methods: {
        updateValue(val, checked) {
            if (checked) {
                if (this.value.indexOf(val) < 0) {
                    this.value.push(val);
                }
            } else {
                this.value.splice(this.value.indexOf(val), 1);
            }
            this.$emit("update:value", this.value);
            this.$emit("change", this.value);
        }
    },
};
</script>

<style>
.group {
    width: 100%;
}
</style>