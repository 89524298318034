<template>
  <div>
    <div class="titlebar-box">
      <van-search shape="round" v-model="dataValue" :placeholder="placeholder" class="titlebar-search" @search="onSearch" />
    </div>
  </div>
</template>

<script>

export default {
  name: "TitleSearch",
  props: {
    placeholder: {
      type: String,
      default: '请输入搜索院校',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
   
    };
  },
  computed: {
    dataValue: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit('update:value', val);
      }
    },
  },
  methods: {
    onSearch() {
      this.$emit("search");
    },
  },
  mounted() {
  }
};
</script>

<style lang="less" scoped>
.titlebar-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-width: 0px;
  background-color: #F6F6F6;
  height: 40px;
}

.titlebar-search {
  flex: 1;
  background-color: transparent;
}

::v-deep .van-search__content {
  border: 1px solid #FE5E03;
  background-color: white;
}
</style>
