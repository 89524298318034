<template>
    <div :class="selected ? 'check-box check-box_checked' : 'check-box'" @click="onClick">
        <div class="check-text">{{ label }}</div>
    </div>
</template>
  
<script>

export default {
    name: "CheckBorder",
    components: {
    },
    inject: {
        CheckBorderGroup: {
            default: null
        }
    },
    computed: {
        selected: {
            get() {
                return this.CheckBorderGroup.value.indexOf(this.name) > -1;
            },
        }
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
        };
    },
    methods: {
        onClick() {
            this.CheckBorderGroup.updateValue(this.name, !this.selected);
            this.$emit('click');
        }
    },
    mounted() {

    }
};
</script>
  
<style scoped>
.check-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #FE5E03;
    border-radius: 20px;
    background-color: white;
    color: #606266;
    font-size: 12px;
    transition: all .3s;
}

.check-box_checked {
    background-color: #FE5E03;
    color: white;
    transition: all .3s;
}

.check-text {
    padding: 5px 10px;
}
</style>
  