<template>
  <div>
    <div class="box flex">
      <div class="text1">学习方式</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value1">
        <radio-item label="全部" :checked="value1.length == 0" @click="onClear1"></radio-item>
        <check-item label="全日制" name="全日制" @click="onClick1"></check-item>
        <check-item label="非全日制" name="非全日制" @click="onClick1"></check-item>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">学位类别</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value2">
        <radio-item label="全部" :checked="value2.length == 0" @click="onClear2"></radio-item>
        <check-item label="学术型硕士" name="学术型硕士" @click="onClick2"></check-item>
        <check-item label="专业型硕士" name="专业型硕士" @click="onClick2"></check-item>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">所属门类</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <radio-item-group class="flex fill" v-model="value3">
        <radio-item label="全部" name="" @click="onClick3"></radio-item>
        <div class="fill">
          <radio-item v-for="(item, index) in majorList1" :key="index" :label="item" :name="item" @click="onClick3"></radio-item>
        </div>
      </radio-item-group>
    </div>
    <div class="margin-column"></div>
    <div v-if="majorList2.length > 0">
      <el-collapse v-model="activeName" style="width: 100%;">
        <el-collapse-item name="1">
          <template #title>
            <div class="box flex">
              <div class="text1">学科类别</div>
              <img src="@/assets/images/pc/right.png" class="img">
              <div class="fill" v-if="activeName.indexOf('1') < 0">
                <div class="select-item" v-if="value4.length == 0">全部</div>
                <div class="select-item" v-for="item in value4">{{ item }}</div>
              </div>
              <check-item-group v-else-if="majorList2.length > 0" class="flex fill" v-model="value4">
                <radio-item label="全部" :checked="value4.length == 0" @click="onClear4"></radio-item>
                <div class="fill">
                  <check-item v-for="(item, index) in majorList2" :key="index" :label="item" :name="item" @click="onClick4"></check-item>
                </div>
              </check-item-group>
            </div>
          </template>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template #title>
            <div class="box flex">
              <div class="text1">备考专业</div>
              <img src="@/assets/images/pc/right.png" class="img">
              <div class="fill" v-if="activeName.indexOf('2') < 0">
                <div class="select-item" v-if="value5.length == 0">全部</div>
                <div class="select-item" v-for="item in value5">{{ item }}</div>
              </div>
              <check-item-group v-else-if="majorList3.length > 0" class="flex fill" v-model="value5">
                <radio-item label="全部" :checked="value5.length == 0" @click="onClear5"></radio-item>
                <div class="fill">
                  <check-item v-for="(item, index) in majorList3" :key="index" :label="item" :name="item" @click="onClick5"></check-item>
                </div>
              </check-item-group>
            </div>
          </template>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">院校位置</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value6">
        <radio-item label="全部" :checked="value6.length == 0" @click="onClear6"></radio-item>
        <div class="fill">
          <div class="flex">
            <div class="area" @click="onClickA">A区</div>
            <div class="fill">
              <check-item label="北京" name="北京" @click="onClick6"></check-item>
              <check-item label="天津" name="天津" @click="onClick6"></check-item>
              <check-item label="河北" name="河北" @click="onClick6"></check-item>
              <check-item label="山西" name="山西" @click="onClick6"></check-item>
              <check-item label="辽宁" name="辽宁" @click="onClick6"></check-item>
              <check-item label="吉林" name="吉林" @click="onClick6"></check-item>
              <check-item label="黑龙江" name="黑龙江" @click="onClick6"></check-item>
              <check-item label="上海" name="上海" @click="onClick6"></check-item>
              <check-item label="江苏" name="江苏" @click="onClick6"></check-item>
              <check-item label="浙江" name="浙江" @click="onClick6"></check-item>
              <check-item label="安徽" name="安徽" @click="onClick6"></check-item>
              <check-item label="福建" name="福建" @click="onClick6"></check-item>
              <check-item label="江西" name="江西" @click="onClick6"></check-item>
              <check-item label="山东" name="山东" @click="onClick6"></check-item>
              <check-item label="河南" name="河南" @click="onClick6"></check-item>
              <check-item label="湖北" name="湖北" @click="onClick6"></check-item>
              <check-item label="湖南" name="湖南" @click="onClick6"></check-item>
              <check-item label="广东" name="广东" @click="onClick6"></check-item>
              <check-item label="重庆" name="重庆" @click="onClick6"></check-item>
              <check-item label="四川" name="四川" @click="onClick6"></check-item>
              <check-item label="陕西" name="陕西" @click="onClick6"></check-item>
            </div>
          </div>
          <div class="flex">
            <div class="area" @click="onClickB">B区</div>
            <div class="fill">
              <check-item label="内蒙古" name="内蒙古" @click="onClick6"></check-item>
              <check-item label="广西" name="广西" @click="onClick6"></check-item>
              <check-item label="海南" name="海南" @click="onClick6"></check-item>
              <check-item label="贵州" name="贵州" @click="onClick6"></check-item>
              <check-item label="云南" name="云南" @click="onClick6"></check-item>
              <check-item label="西藏" name="西藏" @click="onClick6"></check-item>
              <check-item label="甘肃" name="甘肃" @click="onClick6"></check-item>
              <check-item label="青海" name="青海" @click="onClick6"></check-item>
              <check-item label="宁夏" name="宁夏" @click="onClick6"></check-item>
              <check-item label="新疆" name="新疆" @click="onClick6"></check-item>
            </div>
          </div>
        </div>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">院校类型</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value7">
        <radio-item label="全部" :checked="value7.length == 0" @click="onClear7"></radio-item>
        <div class="fill">
          <check-item label="综合类" name="综合类" @click="onClick7"></check-item>
          <check-item label="理工类" name="理工类" @click="onClick7"></check-item>
          <check-item label="农林类" name="农林类" @click="onClick7"></check-item>
          <check-item label="医药类" name="医药类" @click="onClick7"></check-item>
          <check-item label="师范类" name="师范类" @click="onClick7"></check-item>
          <check-item label="语言类" name="语言类" @click="onClick7"></check-item>
          <check-item label="财经类" name="财经类" @click="onClick7"></check-item>
          <check-item label="政法类" name="政法类" @click="onClick7"></check-item>
          <check-item label="体育类" name="体育类" @click="onClick7"></check-item>
          <check-item label="艺术类" name="艺术类" @click="onClick7"></check-item>
          <check-item label="民族类" name="民族类" @click="onClick7"></check-item>
          <check-item label="军事类" name="军事类" @click="onClick7"></check-item>
          <check-item label="其他" name="其他" @click="onClick7"></check-item>
        </div>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">院校特色</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <radio-item-group class="flex fill" v-model="value8">
        <radio-item label="全部" name="" @click="onClear8"></radio-item>
        <div class="fill">
          <radio-item label="985" name="985" @click="onClick8"></radio-item>
          <radio-item label="211" name="211" @click="onClick8"></radio-item>
          <radio-item label="双一流" name="双一流" @click="onClick8"></radio-item>
          <radio-item label="自划线" name="自划线" @click="onClick8"></radio-item>
          <radio-item label="高等院校" name="高等院校" @click="onClick8"></radio-item>
          <radio-item label="科研院所" name="科研院所" @click="onClick8"></radio-item>
        </div>
      </radio-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">特招计划</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <radio-item-group class="flex fill" v-model="value9">
        <radio-item label="全部" name="" @click="onClear9"></radio-item>
        <div class="fill">
          <radio-item label="同等学历" name="同等学历" @click="onClick9"></radio-item>
          <radio-item label="退役士兵计划" name="退役士兵计划" @click="onClick9"></radio-item>
          <radio-item label="少干计划" name="少干计划" @click="onClick9"></radio-item>
          <radio-item label="三支一扶" name="三支一扶" @click="onClick9"></radio-item>
          <radio-item label="大学生西部计划" name="大学生西部计划" @click="onClick9"></radio-item>
          <radio-item label="强军计划" name="强军计划" @click="onClick9"></radio-item>
          <radio-item label="农村特岗教师" name="农村特岗教师" @click="onClick9"></radio-item>
          <radio-item label="赴外汉语教师" name="赴外汉语教师" @click="onClick9"></radio-item>
          <radio-item label="双少生" name="双少生" @click="onClick9"></radio-item>
          <radio-item label="优才计划" name="优才计划" @click="onClick9"></radio-item>
          <radio-item label="援藏计划" name="援藏计划" @click="onClick9"></radio-item>
          <radio-item label="卓越工程师计划" name="卓越工程师计划" @click="onClick9"></radio-item>
          <radio-item label="科教融合专项计划" name="科教融合专项计划" @click="onClick9"></radio-item>
        </div>
      </radio-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex center">
      <div class="text1">考试内容</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <div class="fill">
        <el-select filterable clearable size="small" class="select" v-model="value10" placeholder="外语科目" @change="onClick10">
          <el-option v-for="item in subjectList1" :key="item.SubjectName" :label="`${item.SubjectName}(${item.SubjectCount})`" :value="item.SubjectName"></el-option>
        </el-select>
        <el-select filterable clearable size="small" class="select" v-model="value11" placeholder="专业课一" @change="onClick11">
          <el-option v-for="item in subjectList2" :key="item.SubjectName" :label="`${item.SubjectName}(${item.SubjectCount})`" :value="item.SubjectName"></el-option>
        </el-select>
        <el-select filterable clearable size="small" class="select" v-model="value12" placeholder="专业课二" @change="onClick12">
          <el-option v-for="item in subjectList3" :key="item.SubjectName" :label="`${item.SubjectName}(${item.SubjectCount})`" :value="item.SubjectName"></el-option>
        </el-select>
      </div>
    </div>
    <div class="margin-column"></div>
    <div class="box flex center">
      <div class="text1">研究方向</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <div class="fill">
        <el-select filterable clearable size="small" class="select" v-model="value13" placeholder="研究方向" @change="onClick13">
          <el-option v-for="item in rirectionList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import RadioItem from "@/components/PC/RadioItem";
import RadioItemGroup from "@/components/PC/RadioItemGroup";
import CheckItem from "@/components/PC/CheckItem";
import CheckItemGroup from "@/components/PC/CheckItemGroup";
import Config from "@/api/service";
export default {
  name: "AiSelectionFilter",
  components: {
    RadioItem,
    RadioItemGroup,
    CheckItem,
    CheckItemGroup,
  },
  props: {
    filter1: {
      type: Array,
      default: [],
    },
    filter2: {
      type: Array,
      default: [],
    },
    filter3: {
      type: String,
      default: '',
    },
    filter4: {
      type: Array,
      default: [],
    },
    filter5: {
      type: Array,
      default: [],
    },
    filter6: {
      type: Array,
      default: [],
    },
    filter7: {
      type: Array,
      default: [],
    },
    filter8: {
      type: String,
      default: '',
    },
    filter9: {
      type: String,
      default: '',
    },
    filter10: {
      type: String,
      default: '',
    },
    filter11: {
      type: String,
      default: '',
    },
    filter12: {
      type: String,
      default: '',
    },
    filter13: {
      type: String,
      default: '',
    },
  },
  computed: {
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
    value4: {
      get: function () {
        return this.filter4;
      },
      set: function (val) {
        this.$emit('update:filter4', val);
      }
    },
    value5: {
      get: function () {
        return this.filter5;
      },
      set: function (val) {
        this.$emit('update:filter5', val);
      }
    },
    value6: {
      get: function () {
        return this.filter6;
      },
      set: function (val) {
        this.$emit('update:filter6', val);
      }
    },
    value7: {
      get: function () {
        return this.filter7;
      },
      set: function (val) {
        this.$emit('update:filter7', val);
      }
    },
    value8: {
      get: function () {
        return this.filter8;
      },
      set: function (val) {
        this.$emit('update:filter8', val);
      }
    },
    value9: {
      get: function () {
        return this.filter9;
      },
      set: function (val) {
        this.$emit('update:filter9', val);
      }
    },
    value10: {
      get: function () {
        return this.filter10;
      },
      set: function (val) {
        this.$emit('update:filter10', val);
      }
    },
    value11: {
      get: function () {
        return this.filter11;
      },
      set: function (val) {
        this.$emit('update:filter11', val);
      }
    },
    value12: {
      get: function () {
        return this.filter12;
      },
      set: function (val) {
        this.$emit('update:filter12', val);
      }
    },
    value13: {
      get: function () {
        return this.filter13;
      },
      set: function (val) {
        this.$emit('update:filter13', val);
      }
    },
  },
  data() {
    return {
      activeName: [],
      majorList1: [],
      majorList2: [],
      majorList3: [],
      subjectList1: [],
      subjectList2: [],
      subjectList3: [],
      rirectionList: [],
    };
  },
  methods: {
    getMajorList1() {
      setTimeout(() => {
        this.majorList1 = [];
        this.majorList2 = [];
        this.majorList3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
        };
        this.$axios
          .post(Config.aiSelection.filter.belongCategoryList, model)
          .then((res) => {
            this.majorList1 = res.data.Data.MajorBelongcategorys;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    getMajorList2() {
      setTimeout(() => {
        this.majorList2 = [];
        this.majorList3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
        };
        this.$axios
          .post(Config.aiSelection.filter.firstDisciplineList, model)
          .then((res) => {
            this.majorList2 = res.data.Data.MajorBelongFirstDisciplines;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    getMajorList3() {
      setTimeout(() => {
        this.majorList3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
        };
        this.$axios
          .post(Config.aiSelection.filter.majorCodeNameList, model)
          .then((res) => {
            this.majorList3 = res.data.Data.MajorCodeNames;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    getSubjectList1() {
      setTimeout(() => {
        if (!this.value3)
          return;
        var model = {
          MajorSubjectType: 1,
          MajorFirstSubject2: this.value11,
          MajorFirstSubject3: this.value12,
          MajorLearningWays: this.value1,
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
          MajorCodes: this.value5.map(p => p.substring(0, 6)),
          UniversityProvinces: this.value6,
          UniversityTeachTypes: this.value7,
          UniversityCharacteristic: this.value8,
          MajorSpecialRecruitmentPlan: this.value9,
        };
        this.$axios
          .post(Config.aiSelection.filter.firstSubjectList, model)
          .then((res) => {
            this.subjectList1 = res.data.Data.MajorFirstSubjects;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    getSubjectList2() {
      setTimeout(() => {
        if (!this.value3)
          return;
        var model = {
          MajorSubjectType: 2,
          MajorFirstSubject1: this.value10,
          MajorFirstSubject3: this.value12,
          MajorLearningWays: this.value1,
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
          MajorCodes: this.value5.map(p => p.substring(0, 6)),
          UniversityProvinces: this.value6,
          UniversityTeachTypes: this.value7,
          UniversityCharacteristic: this.value8,
          MajorSpecialRecruitmentPlan: this.value9,
        };
        this.$axios
          .post(Config.aiSelection.filter.firstSubjectList, model)
          .then((res) => {
            this.subjectList2 = res.data.Data.MajorFirstSubjects;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    getSubjectList3() {
      setTimeout(() => {
        if (!this.value3)
          return;
        var model = {
          MajorSubjectType: 3,
          MajorFirstSubject1: this.value10,
          MajorFirstSubject2: this.value11,
          MajorLearningWays: this.value1,
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
          MajorCodes: this.value5.map(p => p.substring(0, 6)),
          UniversityProvinces: this.value6,
          UniversityTeachTypes: this.value7,
          UniversityCharacteristic: this.value8,
          MajorSpecialRecruitmentPlan: this.value9,
        };
        this.$axios
          .post(Config.aiSelection.filter.firstSubjectList, model)
          .then((res) => {
            this.subjectList3 = res.data.Data.MajorFirstSubjects;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    getRirectionList() {
      setTimeout(() => {
        if (!this.value3 || !this.value4 || this.value4.length == 0)
          return;
        var model = {
          MajorLearningWays: this.value1,
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
          MajorCodes: this.value5.map(p => p.substring(0, 6)),
          UniversityProvinces: this.value6,
          UniversityTeachTypes: this.value7,
          UniversityCharacteristic: this.value8,
          MajorSpecialRecruitmentPlan: this.value9,
        };
        this.$axios
          .post(Config.aiSelection.filter.researchDirectionList, model)
          .then((res) => {
            this.rirectionList = res.data.Data.MajorResearchDirections;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    onClear1() {
      this.value1 = [];
      this.onClick1();
    },
    onClear2() {
      this.value2 = [];
      this.onClick2();
    },
    onClear3() {
      this.value3 = '';
      this.onClick3();
    },
    onClear4() {
      this.value4 = [];
      this.onClick4();
    },
    onClear5() {
      this.value5 = [];
      this.onClick5();
    },
    onClear6() {
      this.value6 = [];
      this.onClick6();
    },
    onClear7() {
      this.value7 = [];
      this.onClick7();
    },
    onClear8() {
      this.value8 = '';
      this.onClick8();
    },
    onClear9() {
      this.value9 = '';
      this.onClick9();
    },
    onClickA() {
      this.value6 = ['北京', '天津', '河北', '山西', '辽宁', '吉林', '黑龙江', '上海', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '重庆', '四川', '陕西'];
      this.onClick6();
    },
    onClickB() {
      this.value6 = ['内蒙古', '广西', '海南', '贵州', '云南', '西藏', '甘肃', '青海', '宁夏', '新疆'];
      this.onClick6();
    },
    onClick1() {
      this.value2 = [];
      this.value3 = '';
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getRirectionList();
      this.$emit('filter');
    },
    onClick2() {
      this.value3 = '';
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.getMajorList1();
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getRirectionList();
      this.$emit('filter');
    },
    onClick3() {
      this.activeName = ['1'];
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.getMajorList2();
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getRirectionList();
      this.$emit('filter');
    },
    onClick4() {
      this.activeName = ['1', '2'];
      this.value5 = [];
      this.value6 = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.getMajorList3();
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getRirectionList();
      this.$emit('filter');
    },
    onClick5() {
      this.activeName = ['2'];
      this.value6 = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getRirectionList();
      this.$emit('filter');
    },
    onClick6() {
      this.activeName = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getRirectionList();
      this.$emit('filter');
    },
    onClick7() {
      this.activeName = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getRirectionList();
      this.$emit('filter');
    },
    onClick8() {
      this.activeName = [];
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getRirectionList();
      this.$emit('filter');
    },
    onClick9() {
      this.activeName = [];
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getRirectionList();
      this.$emit('filter');
    },
    onClick10() {
      this.activeName = [];
      this.getSubjectList2();
      this.getSubjectList3();
      this.$emit('filter');
    },
    onClick11() {
      this.activeName = [];
      this.getSubjectList1();
      this.getSubjectList3();
      this.$emit('filter');
    },
    onClick12() {
      this.activeName = [];
      this.getSubjectList1();
      this.getSubjectList2();
      this.$emit('filter');
    },
    onClick13() {
      this.activeName = [];
      this.$emit('filter');
    },
  },
  mounted() {
    this.getMajorList1();
  }
};
</script>

<style lang="less" scoped>
.box {
  font-size: 14px;
}

.img {
  width: 15px;
  height: 15px;
  padding: 10px 0;
  margin-top: 2px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.center {
  align-items: center;
}

.fill {
  flex: 1;
}

.text1 {
  padding: 10px;
  color: black;
  word-spacing: 24px;
}

.area {
  cursor: pointer;
  width: 30px;
  padding: 10px;
  color: gray;
}

.search {
  width: 300px;
  margin-left: 10px;
  margin-top: 5px;
}

.select {
  margin-left: 10px;
}

.margin-column {
  margin: 20px 0;
}

.select-item {
  display: inline-block;
  white-space: pre-wrap;
  color: #FF6602;
  font-size: 14px;
  padding: 10px 10px;
}

::v-deep .el-collapse-item__header {
  min-height: 60px;
  height: unset;
  line-height: unset;
}
</style>
