<template>
    <check-item v-show="visible" :name="name" :label="label" icon @click="onClick">
        <slot></slot>
    </check-item>
</template>

<script>
import CheckItem from "@/components/MT/CheckItem";
export default {
    name: "CheckCell",
    components: {
        CheckItem,
    },
    props: {
        name: {
            type: [String, Object],
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            visible: true,
        };
    },
    methods: {
        setVisible(val) {
            this.visible = val;
        },
        onClick() {
            this.$emit("click");
        },
    },
    mounted() {

    }
};
</script>

<style scoped></style>