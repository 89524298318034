<template>
  <div class="filter-box">
    <van-cell-group :border="false" title="学习方式">
      <check-border-group v-model="value1" class="check-border-group" @change="onChange1">
        <check-border class="check-border" label="全日制" name="全日制" icon></check-border>
        <check-border class="check-border" label="非全日制" name="非全日制" icon></check-border>
      </check-border-group>
    </van-cell-group>
    <van-cell-group :border="false" title="学位类型">
      <check-border-group v-model="value2" class="check-border-group" @change="onChange2">
        <check-border class="check-border" label="专业型硕士" name="专业型硕士" icon></check-border>
        <check-border class="check-border" label="学术型硕士" name="学术型硕士" icon></check-border>
      </check-border-group>
    </van-cell-group>
    <van-cell-group title="选择专业">
      <radio-cell-group clear v-model="value3" title="所属门类" placeholder="请选择所属门类" @change="onChange3">
        <radio-cell v-for="(item, index) in majorList1" :key="index" :name="item" :label="item"></radio-cell>
      </radio-cell-group>
      <check-cell-group v-if="value3" clear v-model="value4" title="学科类别" placeholder="请选择学科类别" @change="onChange4">
        <check-cell v-for="(item, index) in majorList2" :key="index" :name="item" :label="item"></check-cell>
      </check-cell-group>
      <check-cell-group v-if="value4.length > 0" clear v-model="value5" title="备考专业" placeholder="请选择备考专业" @change="onChange5">
        <check-cell v-for="(item, index) in majorList3" :key="index" :name="item" :label="item"></check-cell>
      </check-cell-group>
    </van-cell-group>
    <van-cell-group title="选择学校">
      <check-cell-group clear v-model="value6" title="学校位置" placeholder="请选择学校位置" @change="onChange6">
        <check-cell v-for="(item, index) in schoolProvinces" :key="index" :name="item" :label="item"></check-cell>
      </check-cell-group>
      <check-cell-group clear v-model="value7" title="院校类型" placeholder="请选择院校类型" @change="onChange7">
        <check-cell v-for="(item, index) in schoolTeachTypes" :key="index" :name="item" :label="item"></check-cell>
      </check-cell-group>
      <radio-cell-group clear v-model="value8" title="院校特色" placeholder="请选择院校特色" @change="onChange8">
        <radio-cell v-for="(item, index) in schoolCharacteristics" :key="index" :name="item" :label="item"></radio-cell>
      </radio-cell-group>
      <radio-cell-group clear v-model="value9" title="特招计划" placeholder="请选择特招计划" @change="onChange9">
        <radio-cell v-for="(item, index) in schoolSpecialRecruitmentPlans" :key="index" :name="item" :label="item"></radio-cell>
      </radio-cell-group>
    </van-cell-group>
    <van-cell-group title="选择科目" v-if="value3">
      <radio-cell-group clear v-model="value10" title="外语科目" placeholder="请选择外语科目" @change="onChange10">
        <radio-cell v-for="(item, index) in subjectList1" :key="index" :name="item.SubjectName" :label="`${item.SubjectName}(${item.SubjectCount})`"></radio-cell>
      </radio-cell-group>
      <radio-cell-group clear v-model="value11" title="专业课一" placeholder="请选择专业课一" @change="onChange11">
        <radio-cell v-for="(item, index) in subjectList2" :key="index" :name="item.SubjectName" :label="`${item.SubjectName}(${item.SubjectCount})`"></radio-cell>
      </radio-cell-group>
      <radio-cell-group clear v-model="value12" title="专业课二" placeholder="请选择专业课二" @change="onChange12">
        <radio-cell v-for="(item, index) in subjectList3" :key="index" :name="item.SubjectName" :label="`${item.SubjectName}(${item.SubjectCount})`"></radio-cell>
      </radio-cell-group>
    </van-cell-group>
    <van-cell-group title="研究方向" v-if="value3 && value4.length > 0">
      <radio-cell-group clear v-model="value13" title="研究方向" placeholder="请选择研究方向" @change="onChange13">
        <radio-cell v-for="(item, index) in directionList" :key="index" :name="item" :label="item"></radio-cell>
      </radio-cell-group>
    </van-cell-group>
  </div>
</template>

<script>
import RadioCell from "@/components/MT/RadioCell";
import RadioCellGroup from "@/components/MT/RadioCellGroup";
import CheckCell from "@/components/MT/CheckCell";
import CheckCellGroup from "@/components/MT/CheckCellGroup";
import CheckBorder from "@/components/MT/CheckBorder";
import CheckBorderGroup from "@/components/MT/CheckBorderGroup";
import Config from "@/api/service";
export default {
  name: "AiSelectionFilter",
  components: {
    RadioCell,
    RadioCellGroup,
    CheckCell,
    CheckCellGroup,
    CheckBorder,
    CheckBorderGroup,
  },
  props: {
    filter1: {
      type: Array,
      default: [],
    },
    filter2: {
      type: Array,
      default: [],
    },
    filter3: {
      type: String,
      default: '',
    },
    filter4: {
      type: Array,
      default: [],
    },
    filter5: {
      type: Array,
      default: [],
    },
    filter6: {
      type: Array,
      default: [],
    },
    filter7: {
      type: Array,
      default: [],
    },
    filter8: {
      type: String,
      default: '',
    },
    filter9: {
      type: String,
      default: '',
    },
    filter10: {
      type: String,
      default: '',
    },
    filter11: {
      type: String,
      default: '',
    },
    filter12: {
      type: String,
      default: '',
    },
    filter13: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      schoolProvinces: ['北京', '天津', '河北', '山西', '辽宁', '吉林', '黑龙江', '上海', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '重庆', '四川', '陕西', '内蒙古', '广西', '海南', '贵州', '云南', '西藏', '甘肃', '青海', '宁夏', '新疆'],
      schoolTeachTypes: ['综合类', '理工类', '农林类', '医药类', '师范类', '语言类', '财经类', '政法类', '体育类', '艺术类', '民族类', '军事类', '其他'],
      schoolCharacteristics: ['985', '211', '双一流', '自划线', '高等院校', '科研院所'],
      schoolSpecialRecruitmentPlans: ['同等学历', '退役士兵计划', '少干计划', '三支一扶', '大学生西部计划', '强军计划', '农村特岗教师', '赴外汉语教师', '双少生', '优才计划', '援藏计划', '卓越工程师计划', '科教融合专项计划'],
      search1: null,
      search2: null,
      search3: null,
      search4: null,
      majorList1: [],
      majorList2: [],
      majorList3: [],
      subjectList1: [],
      subjectList2: [],
      subjectList3: [],
      directionList: [],
    };
  },
  computed: {
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
    value4: {
      get: function () {
        return this.filter4;
      },
      set: function (val) {
        this.$emit('update:filter4', val);
      }
    },
    value5: {
      get: function () {
        return this.filter5;
      },
      set: function (val) {
        this.$emit('update:filter5', val);
      }
    },
    value6: {
      get: function () {
        return this.filter6;
      },
      set: function (val) {
        this.$emit('update:filter6', val);
      }
    },
    value7: {
      get: function () {
        return this.filter7;
      },
      set: function (val) {
        this.$emit('update:filter7', val);
      }
    },
    value8: {
      get: function () {
        return this.filter8;
      },
      set: function (val) {
        this.$emit('update:filter8', val);
      }
    },
    value9: {
      get: function () {
        return this.filter9;
      },
      set: function (val) {
        this.$emit('update:filter9', val);
      }
    },
    value10: {
      get: function () {
        return this.filter10;
      },
      set: function (val) {
        this.$emit('update:filter10', val);
      }
    },
    value11: {
      get: function () {
        return this.filter11;
      },
      set: function (val) {
        this.$emit('update:filter11', val);
      }
    },
    value12: {
      get: function () {
        return this.filter12;
      },
      set: function (val) {
        this.$emit('update:filter12', val);
      }
    },
    value13: {
      get: function () {
        return this.filter13;
      },
      set: function (val) {
        this.$emit('update:filter13', val);
      }
    },
  },
  methods: {
    getMajorList1() {
      setTimeout(() => {
        this.majorList1 = [];
        this.majorList2 = [];
        this.majorList3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
        };
        this.$axios
          .post(Config.aiSelection.filter.belongCategoryList, model)
          .then((res) => {
            this.majorList1 = res.data.Data.MajorBelongcategorys;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    getMajorList2() {
      setTimeout(() => {
        this.majorList2 = [];
        this.majorList3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
        };
        this.$axios
          .post(Config.aiSelection.filter.firstDisciplineList, model)
          .then((res) => {
            this.majorList2 = res.data.Data.MajorBelongFirstDisciplines;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    getMajorList3() {
      setTimeout(() => {
        this.majorList3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
        };
        this.$axios
          .post(Config.aiSelection.filter.majorCodeNameList, model)
          .then((res) => {
            this.majorList3 = res.data.Data.MajorCodeNames;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    getSubjectList1() {
      setTimeout(() => {
        if (!this.value3)
          return;
        var model = {
          MajorSubjectType: 1,
          MajorFirstSubject2: this.value11,
          MajorFirstSubject3: this.value12,
          MajorLearningWays: this.value1,
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
          MajorCodes: this.value5.map(p => p.substring(0, 6)),
          UniversityProvinces: this.value6,
          UniversityTeachTypes: this.value7,
          UniversityCharacteristic: this.value8,
          MajorSpecialRecruitmentPlan: this.value9,
        };
        this.$axios
          .post(Config.aiSelection.filter.firstSubjectList, model)
          .then((res) => {
            this.subjectList1 = res.data.Data.MajorFirstSubjects;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    getSubjectList2() {
      setTimeout(() => {
        if (!this.value3)
          return;
        var model = {
          MajorSubjectType: 2,
          MajorFirstSubject1: this.value10,
          MajorFirstSubject3: this.value12,
          MajorLearningWays: this.value1,
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
          MajorCodes: this.value5.map(p => p.substring(0, 6)),
          UniversityProvinces: this.value6,
          UniversityTeachTypes: this.value7,
          UniversityCharacteristic: this.value8,
          MajorSpecialRecruitmentPlan: this.value9,
        };
        this.$axios
          .post(Config.aiSelection.filter.firstSubjectList, model)
          .then((res) => {
            this.subjectList2 = res.data.Data.MajorFirstSubjects;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    getSubjectList3() {
      setTimeout(() => {
        if (!this.value3)
          return;
        var model = {
          MajorSubjectType: 3,
          MajorFirstSubject1: this.value10,
          MajorFirstSubject2: this.value11,
          MajorLearningWays: this.value1,
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
          MajorCodes: this.value5.map(p => p.substring(0, 6)),
          UniversityProvinces: this.value6,
          UniversityTeachTypes: this.value7,
          UniversityCharacteristic: this.value8,
          MajorSpecialRecruitmentPlan: this.value9,
        };
        this.$axios
          .post(Config.aiSelection.filter.firstSubjectList, model)
          .then((res) => {
            this.subjectList3 = res.data.Data.MajorFirstSubjects;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    getDirectionList() {
      setTimeout(() => {
        if (!this.value3 || !this.value4 || this.value4.length == 0)
          return;
        var model = {
          MajorLearningWays: this.value1,
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
          MajorCodes: this.value5.map(p => p.substring(0, 6)),
          UniversityProvinces: this.value6,
          UniversityTeachTypes: this.value7,
          UniversityCharacteristic: this.value8,
          MajorSpecialRecruitmentPlan: this.value9,
        };
        this.$axios
          .post(Config.aiSelection.filter.researchDirectionList, model)
          .then((res) => {
            this.directionList = res.data.Data.MajorResearchDirections;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    onChange1() {
      this.value2 = [];
      this.value3 = '';
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.search1 = null;
      this.search2 = null;
      this.search3 = null;
      this.search4 = null;
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getDirectionList();
      this.$emit('filter');
    },
    onChange2() {
      this.value3 = '';
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.search1 = null;
      this.search2 = null;
      this.search3 = null;
      this.search4 = null;
      this.getMajorList1();
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getDirectionList();
      this.$emit('filter');
    },
    onChange3() {
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.search1 = null;
      this.search2 = null;
      this.search3 = null;
      this.search4 = null;
      this.getMajorList2();
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getDirectionList();
      this.$emit('filter');
      this.popup1 = false;
    },
    onChange4() {
      this.value5 = [];
      this.value6 = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.search1 = null;
      this.search2 = null;
      this.search3 = null;
      this.search4 = null;
      this.getMajorList3();
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getDirectionList();
      this.$emit('filter');
    },
    onChange5() {
      this.value6 = [];
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.search1 = null;
      this.search2 = null;
      this.search3 = null;
      this.search4 = null;
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getDirectionList();
      this.$emit('filter');
    },
    onChange6() {
      this.value7 = [];
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.search1 = null;
      this.search2 = null;
      this.search3 = null;
      this.search4 = null;
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getDirectionList();
      this.$emit('filter');
    },
    onChange7() {
      this.value8 = '';
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.search1 = null;
      this.search2 = null;
      this.search3 = null;
      this.search4 = null;
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getDirectionList();
      this.$emit('filter');
    },
    onChange8() {
      this.value9 = '';
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.search1 = null;
      this.search2 = null;
      this.search3 = null;
      this.search4 = null;
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getDirectionList();
      this.$emit('filter');
      this.popup6 = false;
    },
    onChange9() {
      this.value10 = '';
      this.value11 = '';
      this.value12 = '';
      this.value13 = '';
      this.search1 = null;
      this.search2 = null;
      this.search3 = null;
      this.search4 = null;
      this.getSubjectList1();
      this.getSubjectList2();
      this.getSubjectList3();
      this.getDirectionList();
      this.$emit('filter');
      this.popup6 = false;
    },
    onChange10() {
      this.getSubjectList2();
      this.getSubjectList3();
      this.$emit('filter');
      this.popup7 = false;
    },
    onChange11() {
      this.getSubjectList1();
      this.getSubjectList3();
      this.$emit('filter');
      this.popup8 = false;
    },
    onChange12() {
      this.getSubjectList1();
      this.getSubjectList2();
      this.$emit('filter');
      this.popup9 = false;
    },
    onChange13() {
      this.$emit('filter');
      this.popup10 = false;
    },
  },
  mounted() {
    this.getMajorList1();
  }
};
</script>

<style scoped lang="less">
.filter-box {
  background-color: white;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}

.check-border-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-border {
  width: 48%;
}

.popup {
  height: 80%;
}

.popup-content {
  margin: 0px 10px 10px 10px;
}

.title-area {
  margin: 20px 0 5px 5px;
  font-size: 14px;
  color: gray;
}

::v-deep .van-cell-group__title {
  color: black;
  font-weight: bold;
}
</style>
