import { render, staticRenderFns } from "./AiSelectionItem.vue?vue&type=template&id=6877e89a&scoped=true&"
import script from "./AiSelectionItem.vue?vue&type=script&lang=js&"
export * from "./AiSelectionItem.vue?vue&type=script&lang=js&"
import style0 from "./AiSelectionItem.vue?vue&type=style&index=0&id=6877e89a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6877e89a",
  null
  
)

export default component.exports